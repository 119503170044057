import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPostFeedPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import type { GetPostFeedPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  getPostPage,
  getPostPagePreview,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import type { GetPostPageResponse } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { getSectionPathname } from '../external/common/controller/helpers';
import { Router } from '../external/common/router';
import { normalizePostV3 } from '../external/common/services/post-utils';
import { resolvePostSlug } from '../external/common/services/slug';
import {
  ROUTE_404,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
} from '../external/post-page/constants/routes';
import { customRouteHandler } from '../external/post-page/controller/route-handlers/custom-route-handler';
import { getSectionUrl } from '../external/post-page/services/get-section-url';

const getLanguageCode = (wixCodeApi: IWixAPI) => {
  return (
    wixCodeApi.window.multilingual.currentLanguage ||
    (wixCodeApi.site.language ?? 'en')
  );
};

const getPostPageData = (
  response: GetPostFeedPageResponse | GetPostPageResponse,
) => {
  if ('postPage' in response) {
    return response.postPage;
  } else if ('postFeedPage' in response) {
    return {
      ...response.postFeedPage,
      post: response.postFeedPage?.posts?.posts?.[0],
    };
  }
};

interface GetPostAndCategoriesParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

export const getPostAndCategories = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
}: GetPostAndCategoriesParams) => {
  const { postId, instance } = await extractPostIdAndInstanceFromCurrentUrl(
    wixCodeApi,
    instanceId,
  );

  let postResponsePromise;

  if (flowAPI.environment.isEditor) {
    postResponsePromise = getEditorPostRequest({
      flowAPI,
      languageCode: getLanguageCode(wixCodeApi),
    });
  } else if (postId) {
    postResponsePromise = getViewerPostRequest({
      postId,
      instance,
      wixCodeApi,
      flowAPI,
    });
  } else {
    postResponsePromise = getEditorPostRequest({
      flowAPI,
      languageCode: getLanguageCode(wixCodeApi),
    });
  }

  try {
    const postResponse = await postResponsePromise;

    if (!postResponse.data) {
      throw new Error('No post');
    }

    const postPageData = getPostPageData(postResponse.data);

    if (!postPageData) {
      throw new Error('No post page data available');
    }

    const { post, tags, categories, appData, translations } = postPageData;

    if (!post) {
      throw new Error('No post data available');
    }

    return {
      post: {
        ...normalizePostV3(post),
        tags,
      },
      categories,
      appData,
      translations,
    };
  } catch (error: any) {
    if (error?.response?.status) {
      wixCodeApi.seo.setSeoStatusCode(error.response.status);
    }

    throw error;
  }
};

interface GetViewerPostRequestParams {
  wixCodeApi: IWixAPI;
  flowAPI: ViewerScriptFlowAPI;
  postId: string;
  instance?: string;
}

const extractPostIdAndInstanceFromCurrentUrl = async (
  wixCodeApi: IWixAPI,
  instanceId: string,
) => {
  const router = new Router();
  const pathname = getSectionPathname({
    wixCodeApi,
    sectionUrl: await getSectionUrl({
      wixCodeApi,
      instanceId,
      sectionId: BLOG_POST_PAGE_SECTION_ID,
    }),
  });

  router.add(ROUTE_PREVIEW_POST);
  router.add(ROUTE_POST);
  router.add(ROUTE_POST_ADVANCED_SLUG);
  router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);

  const matchPathResult = await router.matchPath({ pathname });

  if (
    'postId' in matchPathResult.params &&
    'instance' in matchPathResult.params
  ) {
    return matchPathResult.params;
  }

  const postId = resolvePostSlug(matchPathResult.params);

  return {
    postId,
    instance: undefined,
  };
};

const getViewerPostRequest = async ({
  wixCodeApi,
  flowAPI,
  postId,
  instance,
}: GetViewerPostRequestParams) => {
  const params = {
    languageCode: getLanguageCode(wixCodeApi),
    translationsName: 'main',
    loadRichContent: true,
  };

  return instance
    ? flowAPI.httpClient.request(
        getPostPagePreview({
          draftPostId: postId,
          ...params,
        }),
        { signedInstance: instance },
      )
    : flowAPI.httpClient.request(
        getPostPage({
          postId,
          ...params,
        }),
      );
};

interface GetEditorPostRequestParams {
  flowAPI: ViewerScriptFlowAPI;
  languageCode: string;
}

const getEditorPostRequest = ({
  flowAPI,
  languageCode,
}: GetEditorPostRequestParams) => {
  return flowAPI.httpClient.request(
    getPostFeedPage({
      languageCode,
      page: 1,
      pageSize: 1,
      includeContent: true,
      loadRichContent: true,
      includeInitialPageData: true,
    }),
  );
};
